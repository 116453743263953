<template>
  <v-container fluid>
    <report-title :title="options.title" class="mb-2" />
    <!-- <report-sub-category :tabs="options.tabs" @select-tab="resetReport" /> -->
    <!-- <report-filter
      :organizations="options.organizations"
      @change-filter="fetchReport($event)"
    /> -->
    <report-table :headers="options.headers" :items="options.data" />
    <report-alert />
  </v-container>
</template>

<script >
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "DataTransferAuditReport",
  components: {
    ReportTitle: () => import("@/components/report/ReportTitle.vue"),
    // ReportSubCategory: () =>
    //   import("@/components/report/ReportSubCategory.vue"),
    ReportFilter: () => import("@/components/report/ReportFilter.vue"),
    ReportTable: () => import("@/components/report/ReportTable.vue"),
    ReportAlert: () => import("@/components/report/ReportAlert.vue"),
  },
  computed: {
    options: get("report/optionsDataTransferAudit"),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      resetReport: "report/resetDataTransferAuditReport",
      // fetchOrganizations: "report/fetchDataTransferAuditOrganizations",
      fetchReport: "report/fetchDataTransferAuditData",
    }),
  },
  mounted() {
    // this.fetchOrganizations();
    this.resetReport();
    this.fetchReport();
  },
});
</script>